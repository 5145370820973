export const Colors = {
  primary: 'rgb(38,63,142)',
  background: 'rgb(109,76,35)',
  card: 'rgb(255, 255, 255)',
  text: 'rgb(28, 28, 30)',
  border: 'rgb(216, 216, 216)',
  notification: 'rgb(255, 59, 48)',
  theme_background: 'rgb(38,63,142)',
  black_text_color: 'black',
  gray_text_color: '#737272',
  light_gray_text_color: '#f2f2f4',
  white_text_color: '#ffffff',
  blue_color: 'blue',
  amber_color: '#FFC000',
  chinese_silver: '#C8C7C8',
  argent_color: '#C2C0C0',
  BGScreen: '#f7fcff',
  White_Colour: 'white',
  TextWhiteColor: '#fff',
  BorderBox: '#c5c5c5',
  GreenColor: 'green',
  Red: 'red',
  Purple: '#4f57a8',
  Gray_Colour: "gray",
  Blue: "#0A1652",// Oxford Blue
  Orange: '#ff853e',
  Shadow_Color: "#000", // black
  Yellow: "#FFC000", // Amber
  Pink: "#f380d5"
};
export default Colors;