import { combineReducers } from 'redux';
import commonReducer from './commomReducer';
import DataReducer from './DataReducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import authReducer from '../features/users/authReducer'
import { userApiSlice } from '../features/users/userApiSlice';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

const rootReducers = combineReducers({
  commonReducer: persistReducer(persistConfig, commonReducer),
  DataReducer: persistReducer(persistConfig, DataReducer),
  authReducer:persistReducer(persistConfig, authReducer),
  [userApiSlice.reducerPath]:userApiSlice.reducer
});

export default rootReducers;