const RouteName = {
    SPLSH_SCREEN: "SplashScreen",
    SWIPER_SCREEN: "Swiperscreen",
    LOGIN_SCREEN: "LoginScreen",
    REGISTER_SCREEN: "Register",
    OTP_VERYFY_SCREEN: 'OtpVeryfiveScreen',
    HOME_SCREEN: "HomeScreen",
    REGIATRAION_SUCCESSFULL: "RegistrationSuccessful",
    HOME_TAB: 'Home',
    BOOKING_TAB: 'MyBooking',
    PAYMENT_HISTORY: 'PaymentHistory',
    PROFILE_TAB: 'Profile',
    SELECT_LANGUAGE: 'TranslationScreen',
    FORGOT_PASSWORD: 'ForgotPassword',
    CHAT_SCREEN: 'Chatscreen',
    HELP_SCREEN: 'HelpScreen',
    SETTING_SCREEN: 'SettingsScreen',
    FAQ_SCREEN: 'FAQScreen',
    REVIEWS_SCREEN: 'ReviewsScreen',
    NOTIFICTION_SCREEN: 'NotificationScreen',
    BUS_LIST_SCREEN: 'BusListScreen',
    BUS_SEAT_SCREEN: 'BusSeatScreen',
    PAYMENT_SCREEN: 'PaymentScreen',
    CREDIT_CARD_SCREEN: 'CreditCardScreen',
    PAYMENT_SUCCESSFULLY: 'PaymentSuccessFully',
    TICKET_SCREEN: 'TicketScreen',
}
export default RouteName;