import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, ScrollView, TextInput, TouchableOpacity, Linking } from 'react-native';
import { Input, Button, CheckBox, Spacing, Countrycode, DatePicker } from '../../../components';
import { SH, SF, SW, Colors } from '../../../utils';
import { RouteName } from '../../../routes';
import { Login, Style } from '../../../styles';
import IconG from 'react-native-vector-icons/Ionicons';
import { useTranslation } from "react-i18next";
import { useTheme } from '@react-navigation/native';
import Checkbox from 'expo-checkbox';
import { useDispatch } from 'react-redux';
import { useRegisterMutation, useUserQuery } from '../../../redux/features/users/userApiSlice';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

const Register = (props) => {
    const { navigation } = props;
    const { t } = useTranslation();
    const { Colors } = useTheme();
    const [register, { isLoading, status, error }] = useRegisterMutation()
    const Logins = useMemo(() => Login(Colors), [Colors]);
    const Styless = useMemo(() => Style(Colors), [Colors]);
    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [emailId, setemailId] = useState("")
    const [mobileNumber, setmobileNumber] = useState("")
    const [textInputPassword, settextInputPassword] = useState("")
    const [toggleCheckBox, settoggleCheckBox] = useState(false)
    const [birthDate, setbirthDate] = useState("")
    const [street, setstreet] = useState("")
    const [city, setcity] = useState("")
    const [state, setstate] = useState("")
    const [postalCode, setpostalCode] = useState("")
    const [country, setcountry] = useState("")
    const [passwordVisibility, setPasswordVisibility] = useState(true);

    const onChangeText = (text, type) => {
        if (text === 'TextInputPassword') setPasswordVisibility(!passwordVisibility);
    };
    const [registerPressed, setRegisterPressed] = useState(false)
    
    const handleRegister = () => {
        setRegisterPressed(true)
        debugger
        register({
            "email": emailId,
            "password": textInputPassword,
            "firstname": firstName,
            "lastname": lastName,
            "phoneNumber": mobileNumber,
            "birthDate": (new Date(birthDate)).toJSON(),
            "address": {
              "street": street,
              "city": city,
              "state": state,
              "postalCode": postalCode,
              "country": country
            }
        }).unwrap().then(
            () => {
                setRegisterPressed(false)
                navigation.navigate(RouteName.REGIATRAION_SUCCESSFULL)
            }
        )
    }
    return (
        <View style={Logins.MinViewBgColor}>
            <ScrollView
                ContentContainerStyle={Style.ScrollViewStyle}>
                <View style={Logins.Container}>
                    <View style={Styless.MinViewContent}>
                        <View style={Logins.TopSpaceRegister}>
                            <Text style={Logins.RegisterText}>{t("Sign_Up_Text")}</Text>
                        </View>
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("First_Name_Text")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("Enter_Your_First_Name")}
                            onChangeText={(text) => setfirstName(text)}
                            value={firstName}
                        />
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("Last_Name_Text")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("Enter_Your_Last_Name")}
                            onChangeText={(text) => setlastName(text)}
                            value={lastName}
                        />
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("BirthDate")}</Text>
                        </View>
                        <Spacing />
                        <DatePicker
                            onWebDateChange={(text) => setbirthDate(text)}
                            DatePlaceHolder={birthDate}
                        />
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("Mobile_number")}</Text>
                        </View>
                        <Spacing />
                        <View style={Logins.MinVieCountry}>
                            <View style={Logins.DropDownIconFlex}>
                                {/* <Countrycode /> */}
                            </View>
                            <Input
                                placeholder={t("Mobile_Number")}
                                onChangeText={(text) => setmobileNumber(text)}
                                value={mobileNumber}
                                maxLength={10}
                                inputType="numeric"
                                placeholderTextColor={Colors.gray_text_color}
                                inputStyle={Logins.Inputstyle}
                            />
                        </View>
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("Email_Text")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("Enter_Your_Email")}
                            onChangeText={(text) => setemailId(text)}
                            value={emailId}
                            placeholderTextColor={Colors.gray_text_color}
                        />
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("Password_Text")}</Text>
                        </View>
                        <Spacing />
                        <View style={Styless.FlexRowPassword}>
                            <TextInput
                                style={Styless.InputPassword}
                                name="password"
                                value={textInputPassword}
                                placeholder={t("Password_Text")}
                                autoCapitalize="none"
                                autoCorrect={false}
                                placeholderTextColor={Colors.gray_text_color}
                                textContentType="newPassword"
                                secureTextEntry={passwordVisibility}
                                enablesReturnKeyAutomatically
                                onChangeText={(text) => settextInputPassword(text)}
                            />
                            <TouchableOpacity onPress={() => { onChangeText("TextInputPassword") }}>
                                <IconG name={passwordVisibility ? 'eye-off' : 'eye'} size={25} style={Logins.eyeiconset} />
                            </TouchableOpacity>
                        </View>
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("Country")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("Country")}
                            onChangeText={(text) => setcountry(text)}
                            value={country}
                        />
                        <Spacing space={SH(10)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("State")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("State")}
                            onChangeText={(text) => setstate(text)}
                            value={state}
                        />
                        <Spacing space={SH(10)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("City")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("City")}
                            onChangeText={(text) => setcity(text)}
                            value={city}
                        />
                        <Spacing space={SH(10)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("PostalCode")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("PostalCode")}
                            onChangeText={(text) => setpostalCode(text)}
                            value={postalCode}
                        />
                        <Spacing space={SH(10)} />
                        <View style={Logins.TopSpaceRegisterTwo}>
                            <Text style={Logins.FirstNameTextStyle}>{t("Street")}</Text>
                        </View>
                        <Spacing />
                        <Input
                            placeholder={t("Street")}
                            onChangeText={(text) => setstreet(text)}
                            value={street}
                        />
                        <Spacing space={SH(10)} />
                        <View style={Logins.FlexRowChekBox}>
                            <View>
                                <Checkbox disabled={false}
                                    value={toggleCheckBox}
                                    
                                    onValueChange={(text) => settoggleCheckBox(text)} />
                            </View>
                            <Text style={Logins.SimpleTextStyle}>{t("I_Agree_Text")} <Text style={Logins.borderbottomTwo}><Text style={Logins.bluecolor} onPress={() => Linking.openURL('https://myaccount.google.com/')}> {t("Terms_Of_Service")}  </Text></Text>{t("And_text")}  <Text onPress={() => Linking.openURL('https://myaccount.google.com/')} style={Logins.bluecolor}>{t("Privacy_Policy")}</Text></Text>
                        </View>
                        <Spacing space={SH(20)} />
                        <View style={Logins.ButtonView}>
                            <Button
                                spinner={registerPressed}
                                title={t("Sign_Up_Text")}
                                onPress={handleRegister}
                            />
                        </View>
                        <Spacing space={SH(20)} />
                        <View style={Logins.TopSpace}>
                            <View style={Logins.AlredyAndLoginBox}>
                                <Text style={Logins.MemberTextStyle}>{t("Already_Member")}</Text>
                                <TouchableOpacity onPress={() => navigation.navigate(RouteName.LOGIN_SCREEN)}>
                                    <Text style={Logins.LoginScreenText}>{t("Login_Text")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};
export default Register;
