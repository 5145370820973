import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../redux/features/users/userApiSlice";

function isEpochAfter(epoch){
    const exp = (new Date(0))
    exp.setUTCSeconds(epoch)
    return dayjs(exp).isAfter(dayjs())
}

export function useAuth() {
    //debugger
    const authState = useSelector(state => state.authReducer)
    if(authState.tokenPayload===null || !isEpochAfter(authState.tokenPayload.exp)) return {
        isLoggedIn:false,
        user:null
    }
    
    const { data:user, isSuccess, isError } = useGetUserQuery(authState.tokenPayload.jti)
    //debugger

    return {
        isLoggedIn:true,
        user:user
    }
}