import React, { useState, useMemo } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import DateTimePicker from "react-native-modal-datetime-picker";
import { DatePickerStyle } from '../../styles';
import moment from 'moment';
import { useTheme } from '@react-navigation/native';
import { SF, SH, SW, Fonts, Colors } from '../../utils';


function DatePicker(props) {
    const { DatePlaceholder, onWebDateChange } = props;
    const [dateselcet, setdateselcet] = useState(DatePlaceholder);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const { Colors } = useTheme();
    const showDateTimePicker = () => {
        setDatePickerVisibility(true);
    };
    const hideDateTimePicker = () => {
        setDatePickerVisibility(false);
    };
    const handleDatePicked = (date) => {
        if (Platform.OS==='web') {
            onWebDateChange(date.target.value)
        } else{
            hideDateTimePicker()
            setdateselcet(moment(date, "YYYY-MM-DDTHH:mm:ss Z").local().format('DD-MM-YYYY'));
        }
    };
    const styles = {
        container: { width: '100%' },

            input_style: {
              paddingHorizontal: 12,
              width: '100%',
              paddingTop: 12,
              paddingBottom: 7,
              height: SH(47),
              color: Colors.gray_text_color,
              fontSize: SF(17),
              fontFamily: Fonts.Poppins_Medium,
              borderRadius: 7,
              backgroundColor: Colors.White_Colour,
              shadowColor: Colors.Shadow_Color,
              shadowOffset: {
                width: 0,
                height: Platform.OS === 'ios' ? 2 : 25,
              },
              shadowOpacity: 0.58,
              shadowRadius: Platform.OS === 'ios' ? 2 : 25,
              elevation: Platform.OS === 'ios' ? 1 : 2,
    
        },
    
    }
    return (
        <View style={styles.container}>
            <View>
                <TouchableOpacity onPress={() => showDateTimePicker()}>
                    <Text style={DatePickerStyle.datetextstyles}>{dateselcet}</Text>
                </TouchableOpacity>
            </View>
            {Platform.OS==='web'? (
                <input type='date' style={styles.input_style} hidden={isDatePickerVisible} onChange={handleDatePicked}/>
            ):
                 <DateTimePicker
                    isVisible={isDatePickerVisible}
                    onConfirm={handleDatePicked}
                    onCancel={hideDateTimePicker}
                />
            }
        </View>
    )
}
export default DatePicker;