import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { persistStore } from "redux-persist";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { userApiSlice } from "./features/users/userApiSlice";

export const store = configureStore({
    reducer:rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck:false
        }).concat(thunk).concat(userApiSlice.middleware)
            
    }
});
export const persistor = persistStore(store);
