const images = {
   First_Swiper: require('./LottieAnimation/FirstSwiper.json'),
   Two_Swiper: require('./LottieAnimation/TwoSwiper.json'),
   Three_Swiper: require('./LottieAnimation/ThreeSwiper.json'),
   Splash_Swiper: require('./LottieAnimation/SplashScreen.json'),
   Account_created: require('./LottieAnimation/Accountcreated.json'),
   Reviewsimage_screen: require('./LottieAnimation/Reviewsimage.json'),
   Languageanimation: require('./LottieAnimation/languageanimation.json'),
   bus1: require('./LottieAnimation/Bus1.json'),
   bus2: require('./LottieAnimation/Bus2.json'),
   bus3: require('./LottieAnimation/Bus3.json'),
   App_logo: require('./brt-logo.png'),
   Color_picker_image: require('./colorpicker.png'),
   QR_image: require('./qrcode.png'),
   paypal_Icon: require('./paypal.png'),
   paytem_Icon: require('./two_hundred.png'),
   Rupay: require('./rupayimage.png'),
   GooglePay: require('./googlepay.png'),
   PhonePay: require('./phonepay.png'),
   offer_one: require('./Hundred.png'),
   offer_two: require('./Hundred.png'),
   homeslider_1: require('./two_hundred.png'),
   homeslider_2: require('./two_hundred.png'),
   homeslider_3: require('./two_hundred.png'),
   Exclusive_1: require('./two_hundred.png'),
   Exclusive_2: require('./two_hundred.png'),
   Exclusive_3: require('./two_hundred.png'),
   Exclusive_4: require('./two_hundred.png'),
   User_image_one_profile: require('./Hundred.png'),
   paypal: require('./paypal.png'),
   Upi: require('./googlepayupilogo.png'),
   Google_pay: require('./googlepay.png'),
   freechargelogo: require('./freechargelogo.png'),
   phonepay: require('./phonepay.png'),
   Mobikwikimage: require('./Mobikwikimage.png'),
   paytem: require('./paytem.png'),
   Pymentsuceeful_img: require('./One_fifty.png'),
   creditcard_img: require('./Hundred.png'),
}
export default images;