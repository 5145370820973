import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { userApiSlice } from "./userApiSlice";

const initialStateValue = {
    user:null,
    token:null,
    tokenPayload:null
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialStateValue,
  extraReducers: (builder) => {
    builder
      .addMatcher(userApiSlice.endpoints.login.matchFulfilled, (state, {payload}) => {
        state.token = payload
        state.tokenPayload = jwtDecode(payload)})
      .addMatcher(userApiSlice.endpoints.getUser.matchFulfilled, (state, {payload}) => {
        state.user = payload
      })
  },
  reducers: {
    edituser: (state, action) => {
      state.user = {
        ...state.user, ...action.payload
      }
    },
    logoutuser:(state, action) => {
        state.user=null
        state.token = null
        state.tokenPayload=null
    }
  },
});

export const { getUser, edituser, logoutuser, loginuser } = authSlice.actions;

export default authSlice.reducer;
