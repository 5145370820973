import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getUser, loginuser } from './authReducer';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';


export const userApiSlice = createApi({
  reducerPath: 'userApi',
  tagTypes:['user'],
  baseQuery: fetchBaseQuery({
    baseUrl: "http://54.93.220.5:8083/api/v1/users/",
    prepareHeaders(headers,api) {
      //debugger
      const token = api.getState().authReducer.token
      if(token!==null){
        const exp = new Date(0)
        exp.setUTCSeconds((api.getState().authReducer.tokenPayload.exp))
        //debugger
        const isTokenExpired = dayjs(exp).isBefore(dayjs())
        if(!isTokenExpired){
          headers.set("Authorization", "Bearer "+token)
        }
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      register: builder.mutation({
        query: (createUserDto) => ({
          url:`auth/register`,
          method:'POST',
          body:createUserDto
        }),
        transformResponse: (response, meta, arg) => response.data,
        transformErrorResponse:(response, meta, arg) => response.error,
      }),
      activateAccount: builder.query({
        query : (token) => `auth/activate-account?token=${token}`,
        transformResponse: (response, meta, arg) => response.message,
        transformErrorResponse:(response, meta, arg) => response.error, 
      }),
      login: builder.mutation({
        query: (loginDto) => ({
          url:`auth/login`,
          method:'POST',
          body:loginDto
        }),
        transformResponse: (response, meta, arg) => response.data,
        transformErrorResponse:(response, meta, arg) => response.error
      }),
      getUser: builder.query({
        query : (id) => `profile/${id}`,
        invalidatesTags: (result, error, id ) => [{ type: 'user', id }],
        transformResponse: (response, meta, arg) => response.data,
        transformErrorResponse:(response, meta, arg) => response.error
      }),
      updateUser: builder.mutation({
        query: ({id,userDto}) => ({
          url:`profile/${id}`,
          method:'PUT',
          body:userDto
        }),
        invalidatesTags: (result, error, { id }) => [{ type: 'user', id }],
        transformResponse: (response, meta, arg) => response.data,
        transformErrorResponse:(response, meta, arg) => response.error
      }),
      updatePassword: builder.mutation({
        query: ({id,passwdDto}) => ({
          url:`profile/${id}/update-password`,
          method:'PUT',
          body:passwdDto
        }),
        transformResponse: (response, meta, arg) => response.data,
        transformErrorResponse:(response, meta, arg) => response.error
      })
    };
  }
});

export const { useRegisterMutation, useLoginMutation, useActivateAccountQuery, useGetUserQuery, useUpdateUserMutation, useUpdatePasswordMutation } = userApiSlice;