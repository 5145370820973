import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './src/redux/store';
import RootNavigator from './src/routes/RootNavigator';
import { useFonts } from 'expo-font';
import { SplashScreen } from './src/screens';
import { RouteName } from './src/routes';
import { Image } from 'react-native';
import images from './src/images';

const App = () => {
  const [fontsLoaded] = useFonts({
    "Poppins-Regular": require('./assets/fonts/Poppins-Regular.ttf'), // 400
    "Poppins-Medium": require('./assets/fonts/Poppins-Medium.ttf'),  // 500
    "Poppins-Bold": require('./assets/fonts/Poppins-Bold.ttf'),      // 700
    "Poppins-Italic": require('./assets/fonts/Poppins-Italic.ttf'),  // 400
    "Poppins-MediumItalic": require('./assets/fonts/Poppins-MediumItalic.ttf'),  // 500
    "Poppins-BoldItalic": require('./assets/fonts/Poppins-BoldItalic.ttf'),  // 700
  });
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {fontsLoaded ? <RootNavigator/> : <Image style={{
              width:150,
              height:150,
            }} resizeMode='contain' source={images.App_logo} />}
      </PersistGate>
    </Provider>
  );
};
export default App;