import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, ScrollView, Image, TextInput, TouchableOpacity, } from 'react-native';
import { Button, Container, Input, Spacing } from '../../../components';
import { RouteName } from '../../../routes';
import { Style, Login } from '../../../styles';
import { SH, SF, SW, Colors } from '../../../utils';
import IconG from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import images from '../../../index';
import { useTranslation } from "react-i18next";
import { useLoginMutation } from '../../../redux/features/users/userApiSlice';
import { ConfirmationAlert } from '../../../components';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useAuth } from '../../../hooks/auth';


const LoginScreen = (props) => {
    const { navigation } = props;
    const { isLoggedIn } = useAuth()
    if(isLoggedIn) {
        navigation.navigate(RouteName.HOME_SCREEN)
    }
    const { t } = useTranslation();
    const { Colors } = useTheme();
    const Logins = useMemo(() => Login(Colors), [Colors]);
    const Styles = useMemo(() => Style(Colors), [Colors]);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [email, setEmail] = useState('');
    const [passwordVisibility, setpasswordVisibility] = useState(true);
    const [TextInputPassword, setTextInputPassword] = useState('');
    const [loginPressed, setLoginPressed] = useState(false)
    const [okbutton, Setokbutton] = useState('');
    const [login, { isLoading, status, error }] = useLoginMutation()
    const onoknutton = () => {
        if (okbutton === 1) okbutton;
        if (okbutton === 2) navigation.navigate(RouteName.HOME_SCREEN)
    }
    var alertdata = {
        'logout': t("Resand_Otp_Text_Modal"),
        'loginSuccess': t("Login_Successfull"),
    }
    

    const onChangeText = (text) => {
        if (text === 'TextInputPassword') setpasswordVisibility(!passwordVisibility);
    };
    
    const handleLoginPress = () => {
        setLoginPressed(true)
        const data = login({
            username:email,
            password:TextInputPassword
        }).unwrap().then(
            () => {
                setEmail("")
                setTextInputPassword("")
                setLoginPressed(false)
                setAlertVisible(true);
                setAlertMessage(alertdata.loginSuccess);
                Setokbutton(2);
            }
        )
    }

    const OnRegisterPress = () => {
        navigation.navigate(RouteName.REGISTER_SCREEN);
    }

    return (
        <Container>
            <View style={Logins.MinViewScreen}>
                <ScrollView
                    keyboardShouldPersistTaps="handled"
                    ContentContainerStyle={Logins.MainViewForgotPassword}>
                    <View style={Logins.Container}>
                        <View style={Styles.MinViewContent}>
                            <View>
                                <Image style={Logins.ImageSet} resizeMode='contain' source={images.App_logo} />
                            </View>
                            <Text style={Logins.LoginText}>{t("Login_Text")}</Text>
                            <Spacing space={SH(20)} />
                            <View style={Logins.InputSpaceView}>
                                <Input
                                    placeholder={t("Email_Text")}
                                    onChangeText={(value) => setEmail(value)}
                                    value={email}
                                    inputType="email"
                                    placeholderTextColor={Colors.gray_text_color}
                                />
                            </View>
                            <Spacing space={SH(20)} />
                            <View style={Styles.FlexRowPassword}>
                                <TextInput
                                    style={Styles.InputPassword}
                                    name="password"
                                    value={TextInputPassword}
                                    placeholder={t("Password_Text")}
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    placeholderTextColor={Colors.gray_text_color}
                                    textContentType="newPassword"
                                    secureTextEntry={passwordVisibility}
                                    enablesReturnKeyAutomatically
                                    onChangeText={(text) => setTextInputPassword(text)}
                                />
                                <TouchableOpacity onPress={() => { onChangeText("TextInputPassword") }}>
                                    <IconG name={passwordVisibility ? 'eye-off' : 'eye'} size={SF(25)} />
                                </TouchableOpacity>
                            </View>
                            <Spacing space={SH(10)} />
                            <View style={Logins.ViewTextStyle}>
                                <Text style={Logins.TextStyle}>{t("Dont_Have_Account")} <Text style={Logins.registerTextStyle} onPress={() => OnRegisterPress()}> {t("Register_Text")}</Text></Text>
                            </View>
                            <Spacing space={SH(20)} />
                            <View style={Logins.LoginButton}>
                                <Button
                                    spinner={loginPressed}
                                    title={t("Login_Text")}
                                    onPress={handleLoginPress}
                                />
                            </View>
                            <Spacing space={SH(10)} />
                            <TouchableOpacity onPress={() => navigation.navigate(RouteName.FORGOT_PASSWORD)}>
                                <Text style={Logins.ForgetPasswordStyles}>{t("Forgot_Password")}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
            <ConfirmationAlert
                message={alertMessage}
                modalVisible={alertVisible}
                setModalVisible={setAlertVisible}
                onPress={() => { setAlertVisible(!alertVisible), onoknutton() }}
                ButtonMinView={Style.Buttonotp}
                iconVisible={true}
                buttonText={t("Ok")}
            />
        </Container>
    );
}
export default LoginScreen;